import React from 'react';
import {
    Avatar, Brand, Grid, GridItem, DatePicker, Dropdown, DropdownGroup, DropdownItem, DropdownToggle, Masthead, MastheadBrand,
    MastheadContent, MastheadMain, MastheadToggle, Page, PageSection, PageToggleButton, Toolbar, ToolbarContent, ToolbarGroup,
    ToolbarItem, OverflowMenu, OverflowMenuContent, OverflowMenuGroup, OverflowMenuItem, Button, Modal, ModalVariant, SelectVariant,
    TextInput, Alert, Spinner, Select, SelectOption, Tabs, Tab, TabTitleText, PageGroup, PageNavigation, Nav, NavList, NavItem, PageBreadcrumb, BreadcrumbItem, Breadcrumb, Bullseye, Form, FormGroup, CardBody, Card, Flex, FlexItem, TextContent, TextVariants, Text, ButtonVariant, OverflowMenuControl, OverflowMenuDropdownItem, KebabToggle, FormSelectOption, TextArea
} from '@patternfly/react-core';
import BarsIcon from '@patternfly/react-icons/dist/esm/icons/bars-icon';
import imgAvatar from '@patternfly/react-core/src/components/Avatar/examples/avatarImg.svg';
import { TableComposable, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table';
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';
import SaveIcon from '@patternfly/react-icons/dist/esm/icons/edit-alt-icon';
import { getWeekTimeEntries, createTimeEntry, updateTimeEntry, getProjectList, getLocationList, deleteTimeEntry, getCheckInCheckOutEntry } from '../actions/actions';
import { invalidateSession } from '../AppSession';
import moment from 'moment';
import logo from '../Final_variation-Insonix_Logo.png';
import TimeField from 'react-simple-timefield';

class TimeEntry extends React.Component {

    state = {
        isDropdownOpen: false,
        isEditModalOpen: false,
        locationDropdownIsOpen: false,
        activeTabKey: 0,
        isBox: false,
        isAddModalOpen: false,

        // apis data,
        selectedDate: moment().format("YYYY-MM-DD"),
        entries: {},
        newEntry: { "entryType": "LI", "entryDate": moment().format("YYYY-MM-DD"), fromTime: "00:00", thruTime: "00:00" },
        existingEntry: { "entryType": "LI", "entryDate": moment().format("YYYY-MM-DD"), fromTime: "00:00", thruTime: "00:00" },
        projectList: [],
        locationList: [],
        projectDropdown: false,
        locationDropdown: false,
        entryList: [],
        tabIndex: 0,
        checkIn: 0,
        isOpen: false,
        toast: { enabled: false, message: "", variant: "info" },
        totalHours: 0,
        loader: true,
        selectedTabDate: moment().format("YYYY-MM-DD"),
        today: moment().format("YYYY-MM-DD")
    };

    onToggle = isOpen => {
        this.setState({
            isOpen
        });
    };
    onSelect = event => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    componentDidMount() {
        this.verifyCheckInCheckOutEntry();
    }

    verifyCheckInCheckOutEntry = () => {
        getCheckInCheckOutEntry(this.state.selectedDate).then((response) => {
            let checkIn = false;
            let checkOut = false;
            let entryNotFound = false;
            if (response.data.length > 0) {
                response.data.map((ent) => {
                    if (ent.entryType === "CI") checkIn = true;
                    if (ent.entryType === "CO") checkOut = true;
                })
            } else {
                entryNotFound = true;
            }

            if (entryNotFound) {
                this.setState({
                    checkIn: 0
                }, () => {
                    this.getProjects();
                })
            } else {
                if (checkIn && checkOut) {
                    this.setState({
                        checkIn: 2
                    }, () => {
                        this.getProjects();
                    })
                } else if (checkOut) {
                    this.setState({
                        checkIn: 0
                    }, () => {
                        this.getProjects();
                    })
                } else if (checkIn) {
                    this.setState({
                        checkIn: 1
                    }, () => {
                        this.getProjects();
                    })
                }
            }
        })
    }

    getProjects = () => {
        getProjectList().then((projects) => {
            this.setState({
                projectList: projects.data
            }, () => {
                this.getLocations();
            })
        })
    }

    getLocations = () => {
        getLocationList().then((locations) => {
            this.setState({
                locationList: locations.data
            }, () => {
                this.getTimeEntriesBasedOnSelectedDate();
            })
        })
    }

    getTimeEntriesBasedOnSelectedDate = () => {
        getWeekTimeEntries(this.state.selectedDate).then((res) => {
            const { projectList, locationList } = this.state;
            let list = [];
            if (res.data.entryList) {
                res.data.entryList.map(ent => {
                    let proj = projectList.find(pro => pro.id === ent.projectId);
                    let loc = locationList.find(pro => pro.id === ent.locationId);
                    ent.project = { id: proj.id, projectName: proj.projectName };
                    ent.location = { id: loc.id, locationName: loc.locationName };
                    list.push(ent);
                })
            }


            res.data.entryList = list;
            this.setState({ entries: res.data, loader: false }, () => {
                let currDate = new Date(this.state.entries.fromDate);
                let selDate = new Date(this.state.selectedDate);
                let dayNumber = selDate.getDay() - currDate.getDay();
                this.setCurrentDate(dayNumber);
            });
        })
    }

    handleTabClick = (event, tabIndex) => {
        this.setState({
            activeTabKey: tabIndex
        });
    };

    onDropdownToggle = isOpen => {
        this.setState({
            isDropdownOpen: isOpen
        });
    };

    onDropdownSelect = () => {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        });
    };

    handleEditModalToggle = (entry) => {
        const { projectList, locationList } = this.state;
        let proj = projectList.find(pro => pro.id === entry.projectId);
        let loc = locationList.find(pro => pro.id === entry.locationId);
        entry.project = { id: proj.id, projectName: proj.projectName };
        entry.location = { id: loc.id, locationName: loc.locationName };
        let from = entry.fromTime;
        if (from.includes(':')) {

        } else {
            from = from.split('');
            from.splice(2, 0, ":");
            from = from.join('');
            entry.fromTime = from;
        }
        // let frTime = Array.from(entry.fromTime);
        // frTime.splice(2, 0, ':');
        // entry.fromTime = frTime;

        if (entry.thruTime) {
            let eee = entry.thruTime;
            if (eee.includes(':')) {

            } else {
                eee = eee.split('');
                eee.splice(2, 0, ":");
                eee = eee.join('');
            }
            entry.thruTime = eee;
        } else {
            entry.thruTime = "00:00";
        }
        this.setState({
            existingEntry: entry,
            isEditModalOpen: !this.state.isEditModalOpen
        });
    };

    handleEditCloseModalToggle = () => {
        this.setState({
            isEditModalOpen: !this.state.isEditModalOpen
        });
    }

    handleAddModalToggle = () => {
        this.setState({
            existingEntry: { "entryType": "LI", "entryDate": moment().format("YYYY-MM-DD"), fromTime: "00:00", thruTime: "00:00" },
            newEntry: { "entryType": "LI", "entryDate": moment().format("YYYY-MM-DD"), fromTime: "00:00", thruTime: "00:00" },
            isAddModalOpen: !this.state.isAddModalOpen
        });
    };

    handleNewEntry = (name, value) => {
        const { newEntry } = this.state;
        if (name === "location") {
            this.setState({ newEntry: { ...newEntry, location: { id: value } } });
        } else if (name === "project") {
            this.setState({ newEntry: { ...newEntry, project: { id: value } } });
        } else if (name === "thruTime") {
            this.setState({ newEntry: { ...newEntry, [name]: value, entryType: "LIO" } });
        } else {
            this.setState({ newEntry: { ...newEntry, [name]: value } });
        }
    }

    handleEditEntry = (name, value) => {
        const { existingEntry } = this.state;
        if (name === "location") {
            this.setState({ existingEntry: { ...existingEntry, location: { id: value } } });
        } else if (name === "project") {
            this.setState({ existingEntry: { ...existingEntry, project: { id: value } } });
        } else if (name === "thruTime") {
            this.setState({ existingEntry: { ...existingEntry, [name]: value, entryType: "LIO" } });
        } else {
            this.setState({ existingEntry: { ...existingEntry, [name]: value } });
        }
    }

    handleTimeSelection = (e) => {
        const { newEntry } = this.state;
        const { name, value } = e.target;
        this.setState({ newEntry: { ...newEntry, [name]: value } });
    }

    handleEditTimeSelection = (e) => {
        const { existingEntry } = this.state;
        const { name, value } = e.target;
        this.setState({ existingEntry: { ...existingEntry, [name]: value } });
    }

    addEntry = () => {
        const { newEntry, toast } = this.state;
        let fTime = newEntry.fromTime;
        if (newEntry.fromTime && newEntry.entryDate && newEntry.location && newEntry.project) {
            newEntry.fromTime = fTime.replace(":", "");
            newEntry.entryDate = this.state.selectedTabDate;
            delete newEntry.thruTime;
            createTimeEntry(newEntry).then((res) => {
                if (res.status === 200) {
                    if (res.data.error) {
                        this.setState({
                            toast: {
                                ...toast,
                                enabled: true,
                                message: res.data.error,
                                variant: "danger"
                            }
                        })

                        setTimeout(() => {
                            this.setState({
                                toast: {
                                    ...toast,
                                    enabled: false,
                                    message: "",
                                    variant: ""
                                }
                            })
                        }, 3000);
                        this.getTimeEntriesBasedOnSelectedDate();
                    } else {
                        this.setState({
                            toast: {
                                ...toast,
                                enabled: true,
                                message: "Time entry deleted successfully",
                                variant: "info"
                            }
                        })

                        setTimeout(() => {
                            this.setState({
                                toast: {
                                    ...toast,
                                    enabled: false,
                                    message: "",
                                    variant: ""
                                }
                            })
                        }, 3000);
                        this.getTimeEntriesBasedOnSelectedDate();
                    }
                } else {
                    this.setState({
                        toast: {
                            ...toast,
                            enabled: true,
                            message: "Something went wrong. Please try again",
                            variant: "danger"
                        }
                    })

                    setTimeout(() => {
                        this.setState({
                            toast: {
                                ...toast,
                                enabled: false,
                                message: "",
                                variant: "danger"
                            }
                        })
                    }, 3000);
                    this.getTimeEntriesBasedOnSelectedDate();
                }
            })
            this.handleAddModalToggle();
        } else {
            alert("Note: - (*) donated field(s) are mandatory ...");
        }
    }

    updateEntry = () => {
        const { existingEntry, toast } = this.state;
        let fTime = existingEntry.fromTime;
        let tTime = existingEntry.thruTime;
        if (existingEntry.fromTime && existingEntry.entryDate && existingEntry.location && existingEntry.project) {
            existingEntry.fromTime = fTime.replace(":", "");
            existingEntry.thruTime = tTime.replace(":", "");
            updateTimeEntry(existingEntry).then((updatedEntry) => {
                if (updatedEntry.status === 200) {
                    if (updatedEntry.data.error) {
                        this.getTimeEntriesBasedOnSelectedDate();
                        this.setState({
                            toast: {
                                ...toast,
                                enabled: true,
                                message: updatedEntry.data.error,
                                variant: "danger"
                            }
                        })
                    } else {
                        this.getTimeEntriesBasedOnSelectedDate();
                        this.setState({
                            toast: {
                                ...toast,
                                enabled: true,
                                message: "Time entry updated successfully",
                                variant: "info"
                            }
                        })
                    }

                    setTimeout(() => {
                        this.setState({
                            toast: {
                                ...toast,
                                enabled: false,
                                message: "",
                                variant: "info"
                            }
                        })
                    }, 3000);
                } else {
                    this.getTimeEntriesBasedOnSelectedDate();
                    this.setState({
                        toast: {
                            ...toast,
                            enabled: true,
                            message: "Something went wrong. Please try again",
                            variant: "danger"
                        }
                    })

                    setTimeout(() => {
                        this.setState({
                            toast: {
                                ...toast,
                                enabled: false,
                                message: "",
                                variant: "danger"
                            }
                        })
                    }, 3000);
                }
            })
            this.handleEditCloseModalToggle();
        } else {
            alert("Note: - (*) donated field(s) are mandatory ...");
        }
    }

    onToggleProjectDropdown = () => {
        this.setState(prevState => ({
            projectDropdown: !prevState.projectDropdown
        }))
    }

    onToggleLocationDropdown = () => {
        this.setState(prevState => ({
            locationDropdown: !prevState.locationDropdown
        }))
    }

    onSelectProject = (e, project, action) => {
        const { newEntry, existingEntry, projectList } = this.state;
        let proj = projectList.find(pro => pro.projectName === project);
        if (action === 'edit') {
            this.setState({ existingEntry: { ...existingEntry, project: { id: proj.id, projectName: proj.projectName } }, projectDropdown: false });
        } else {
            this.setState({ newEntry: { ...newEntry, project: { id: proj.id, projectName: proj.projectName } }, projectDropdown: false });
        }
    }

    onSelectLocation = (e, location, action) => {
        const { newEntry, existingEntry, locationList } = this.state;
        let loc = locationList.find(pro => pro.locationName === location);
        if (action === 'edit') {
            this.setState({ existingEntry: { ...existingEntry, location: { id: loc.id, locationName: loc.locationName } }, locationDropdown: false });
        } else {
            this.setState({ newEntry: { ...newEntry, location: { id: loc.id, locationName: loc.locationName } }, locationDropdown: false });
        }
    }

    handleDate = (date) => {
        this.setState({
            selectedDate: date
        }, () => {
            this.verifyCheckInCheckOutEntry();
        });
    }

    removeEntry = (id) => {
        const { toast } = this.state;
        deleteTimeEntry(id).then((ress) => {
            if (ress.status === 200) {
                this.getTimeEntriesBasedOnSelectedDate();
                this.setState({
                    toast: {
                        ...toast,
                        enabled: true,
                        message: "Time entry updated successfully",
                        variant: "info"
                    }
                })

                setTimeout(() => {
                    this.setState({
                        toast: {
                            ...toast,
                            enabled: false,
                            message: "",
                            variant: "info"
                        }
                    })
                }, 3000);
            } else {
                this.getTimeEntriesBasedOnSelectedDate();
                this.setState({
                    toast: {
                        ...toast,
                        enabled: true,
                        message: "Something went wrong. Please try again",
                        variant: "danger"
                    }
                })

                setTimeout(() => {
                    this.setState({
                        toast: {
                            ...toast,
                            enabled: false,
                            message: "",
                            variant: "danger"
                        }
                    })
                }, 3000);
            }
        })
    }

    setCurrentDate = (day) => {
        let selectedDay = new Date(new Date().setDate(new Date(this.state.entries.fromDate).getDate() + parseInt(day)));
        let currentDate = new Date(moment(selectedDay).format('YYYY-MM-DD')).getTime();
        this.setState({
            selectedTabDate: moment(currentDate).format('YYYY-MM-DD')
        });
        let list = [];
        let hours = [];
        let newDate;
        this.state.entries.entryList.map((entry) => {
            let date = new Date(entry.entryDate).getTime();
            newDate = new Date(entry.entryDate);
            if (currentDate === date) {
                if (entry.fromTime && entry.thruTime) {
                    hours.push(parseInt(entry.thruTime) - parseInt(entry.fromTime));
                }
                list.push(entry);
            }
        })

        let totH = 0;
        let hrs = 0;
        if (hours.length > 0) {
            Promise.all(hours.map(h => {
                hrs = hrs + h;
            })).then(() => {
                totH = parseFloat(parseInt(hrs) / 100).toFixed(2);
                this.setState({
                    totalHours: totH,
                    entryList: list,
                    activeTabKey: parseInt(day)
                })
            })
        } else {
            this.setState({
                totalHours: totH,
                entryList: list,
                activeTabKey: parseInt(day)
            })
        }
    }

    makeCheckInEntry = () => {
        let obj = {
            "entryType": "CI",
            "entryDate": this.state.selectedDate
        }
        createTimeEntry(obj).then(() => {
            this.verifyCheckInCheckOutEntry();
        })
    }

    makeCheckOutEntry = () => {
        let obj = {
            "entryType": "CO",
            "entryDate": this.state.selectedDate
        }
        createTimeEntry(obj).then(() => {
            this.verifyCheckInCheckOutEntry();
        })
    }

    calendarRangeValidator = (date) => {
        const maxDate = new Date()
        if (date > maxDate) {
            return 'Date is after the allowable range.';
        }
        return '';
    }

    render() {
        const { newEntry, existingEntry, projectList, projectDropdown, locationList, locationDropdown, selectedDate, checkIn } = this.state;

        const userDropdownItems = [<DropdownGroup key="group 2">
            {/* <DropdownItem key="group 2 profile">My profile</DropdownItem>
            <DropdownItem key="group 2 user" component="button">
                User management
            </DropdownItem> */}
            <DropdownItem key="group 2 logout" onClick={() => invalidateSession()}>Logout</DropdownItem>
        </DropdownGroup>];

        const headerToolbar = <Toolbar id="toolbar" isFullHeight isStatic>
            <ToolbarContent>
                <ToolbarGroup variant="icon-button-group" alignment={{
                    default: 'alignRight'
                }} spacer={{
                    default: 'spacerNone',
                    md: 'spacerMd'
                }}>

                </ToolbarGroup>
                <ToolbarItem visibility={{
                    // default: 'hidden',
                    md: 'visible'
                }}>
                    <Dropdown isFullHeight onSelect={this.onDropdownSelect} isOpen={this.state.isDropdownOpen} toggle={<DropdownToggle icon={<Avatar src={imgAvatar} alt="Avatar" />} onToggle={this.onDropdownToggle}>
                        {localStorage.getItem('USER') ? localStorage.getItem('USER') : "User"}
                    </DropdownToggle>} dropdownItems={userDropdownItems} />
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>;


        const navBarHamBurgerAndLogo = <Masthead id="basic-example" >
            <MastheadToggle>
                <PageToggleButton variant="plain" aria-label="Global navigation">
                    <BarsIcon />
                </PageToggleButton>
            </MastheadToggle>
            <MastheadMain>
                <MastheadBrand>
                    <Brand src={logo} alt="Fallback patternfly default logo" widths={{ default: '120px', sm: '120px', md: '120px' }}>
                        <source media="(min-width: 1200px)" srcSet={logo} />
                        <source media="(min-width: 992px)" srcSet={logo} />
                        <source media="(min-width: 768px)" srcSet={logo} />
                        <source media="(min-width: 576px)" srcSet={logo} />
                        <source srcSet={logo} />
                    </Brand>
                </MastheadBrand>
            </MastheadMain>
            <MastheadContent>{headerToolbar}</MastheadContent>
        </Masthead>;

        //Preparing task selection list to display into dropdown
        const createSelectTaskItems = () => {
            //no need to execute this for every dropdown creation
            const taskItems = [];
            if (projectList.length > 0) {
                projectList.map((task, index) => {
                    taskItems.push(<SelectOption id={task.id} key={index} value={task.projectName} />)
                })
            }
            return taskItems
        }

        // no need to create them again for each table row
        const dropdownTaskItems = createSelectTaskItems();

        //Preparing task selection list to display into dropdown
        const createSelectLocationItems = () => {
            //no need to execute this for every dropdown creation
            const taskItems = [];
            if (locationList.length > 0) {
                locationList.map((task, index) => {
                    taskItems.push(<SelectOption id={task.id} key={index} value={task.locationName} />)
                })
            }
            return taskItems
        }

        // no need to create them again for each table row
        const dropdownLocationItems = createSelectLocationItems();

        return (
            <>
                <Page
                    header={navBarHamBurgerAndLogo}
                    mainContainerId='main-content'
                // className='main-page'
                >
                    <PageSection variant='light'>
                        <PageGroup>
                            <Bullseye>
                                <Form isHorizontal>
                                    <FormGroup
                                        label="Please select a date:"
                                        fieldId="horizontal-form-name"
                                    >
                                        <DatePicker value={selectedDate} onChange={(e, newDate) => this.handleDate(newDate)} validators={[this.calendarRangeValidator]} weekStart={1} />
                                    </FormGroup>
                                </Form>
                            </Bullseye>

                            {checkIn === 1 ?
                                <>
                                    {this.state.toast.enabled === true ?
                                        <Alert variant={this.state.toast.variant} title={this.state.toast.message} /> :
                                        null
                                    }
                                    {this.state.loader === false ?

                                        <>
                                            <PageSection variant='light' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Grid hasGutter>
                                                    <GridItem sm={12} md={7} lg={8} xl={9}>
                                                        <Tabs scrolling={false} activeKey={this.state.activeTabKey} onSelect={this.handleTabClick} isBox={true} aria-label="Tabs in the default overflow example" role="region">
                                                            <Tab eventKey={0} onClick={() => this.setCurrentDate("0")} title={<TabTitleText>Monday  {this.state.activeTabKey === 0 ? <span class="badge">{this.state.totalHours}</span> : null}</TabTitleText>} aria-label="Default overflow content users" />
                                                            <Tab eventKey={1} onClick={() => this.setCurrentDate("1")} title={<TabTitleText>Tuesday  {this.state.activeTabKey === 1 ? <span class="badge">{this.state.totalHours}</span> : null}</TabTitleText>} aria-label="Default overflow content users" />
                                                            <Tab eventKey={2} onClick={() => this.setCurrentDate("2")} title={<TabTitleText>Wednesday {this.state.activeTabKey === 2 ? <span class="badge">{this.state.totalHours}</span> : null}</TabTitleText>} aria-label="Default overflow content users" />
                                                            <Tab eventKey={3} onClick={() => this.setCurrentDate("3")} title={<TabTitleText>Thrusday  {this.state.activeTabKey === 3 ? <span class="badge">{this.state.totalHours}</span> : null}</TabTitleText>} aria-label="Default overflow content users" />
                                                            <Tab eventKey={4} onClick={() => this.setCurrentDate("4")} title={<TabTitleText>Friday  {this.state.activeTabKey === 4 ? <span class="badge">{this.state.totalHours}</span> : null}</TabTitleText>} aria-label="Default overflow content users" />
                                                            <Tab eventKey={5} onClick={() => this.setCurrentDate("5")} title={<TabTitleText>Saturday  {this.state.activeTabKey === 5 ? <span class="badge">{this.state.totalHours}</span> : null}</TabTitleText>} aria-label="Default overflow content users" />
                                                            <Tab eventKey={6} onClick={() => this.setCurrentDate("6")} title={<TabTitleText>Sunday  {this.state.activeTabKey === 6 ? <span class="badge">{this.state.totalHours}</span> : null}</TabTitleText>} aria-label="Default overflow content users" />
                                                        </Tabs>
                                                    </GridItem>
                                                    <GridItem sm={12} md={5} lg={4} xl={3}>
                                                        <Bullseye>
                                                            <Flex>
                                                                <FlexItem>
                                                                    <Button onClick={this.handleAddModalToggle}>Add New Entry</Button>
                                                                </FlexItem>
                                                                <FlexItem>
                                                                    <Button onClick={this.makeCheckOutEntry}>Check Out</Button>
                                                                </FlexItem>
                                                            </Flex>
                                                        </Bullseye>
                                                    </GridItem>
                                                </Grid>
                                            </PageSection>
                                            <PageSection variant='light' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <TableComposable aria-label="TimeEntry Table">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>SN</Th>
                                                            <Th>Date</Th>
                                                            <Th>From</Th>
                                                            <Th>Thru</Th>
                                                            <Th>Project</Th>
                                                            <Th>Location</Th>
                                                            <Td></Td>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.entryList !== undefined ?
                                                            this.state.entryList.map((entry, index) => {
                                                                let frTime = entry.fromTime;
                                                                if (!frTime.includes(':')) {
                                                                    frTime = Array.from(entry.fromTime);
                                                                    frTime.splice(2, 0, ':');
                                                                }

                                                                let thTime = entry.thruTime;
                                                                if (thTime && !thTime.includes(':')) {
                                                                    thTime = Array.from(entry.thruTime);
                                                                    thTime.splice(2, 0, ':');
                                                                }
                                                                return (
                                                                    <Tr>
                                                                        <Td dataLabel="SN">{index + 1}</Td>
                                                                        <Td>{entry.entryDate}</Td>
                                                                        <Td dataLabel="SN">{frTime}</Td>
                                                                        <Td dataLabel="SN">{thTime ? thTime : ""}</Td>
                                                                        <Td dataLabel="SN">{entry.project.projectName}</Td>
                                                                        <Td dataLabel="SN">{entry.location.locationName}</Td>
                                                                        <Td isActionCell>
                                                                            <OverflowMenu breakpoint="lg">
                                                                                <OverflowMenuContent>
                                                                                    <OverflowMenuGroup groupType="button">
                                                                                        <OverflowMenuItem>
                                                                                            <Button variant={ButtonVariant.plain} onClick={() => this.handleEditModalToggle(entry)} aria-label="Save">
                                                                                                <SaveIcon />
                                                                                            </Button>
                                                                                        </OverflowMenuItem>
                                                                                        <OverflowMenuItem>
                                                                                            <Button variant={ButtonVariant.plain} onClick={() => this.removeEntry(entry.id)} aria-label="Trash">
                                                                                                <TrashIcon />
                                                                                            </Button>
                                                                                        </OverflowMenuItem>
                                                                                    </OverflowMenuGroup>
                                                                                </OverflowMenuContent>
                                                                                {/* <OverflowMenuControl>
                                                                        <Dropdown
                                                                            onSelect={this.onSelect}
                                                                            toggle={<KebabToggle onToggle={this.onToggle} />}
                                                                            isOpen={isOpen}
                                                                            isPlain
                                                                            dropdownItems={dropdownItems}
                                                                            isFlipEnabled
                                                                            menuAppendTo="parent"
                                                                        />
                                                                    </OverflowMenuControl> */}
                                                                            </OverflowMenu>
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })
                                                            : null}
                                                    </Tbody>
                                                </TableComposable>
                                            </PageSection>
                                        </> :
                                        <Spinner style={{ position: 'absolute', left: '50%', top: '30%', zIndex: 999 }} />}

                                </> :
                                checkIn === 0 ?
                                    <>
                                        <PageSection variant='light' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <PageGroup>
                                                <Bullseye>
                                                    <Button key="confirm" variant="primary" onClick={this.makeCheckInEntry}>
                                                        Check In
                                                    </Button>
                                                </Bullseye>
                                            </PageGroup>
                                        </PageSection>

                                    </> :
                                    checkIn === 2 ?
                                        <>
                                            <PageSection variant='light' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <PageGroup>
                                                    <Bullseye>
                                                        <TextContent>
                                                            <Text component={TextVariants.p}>
                                                                You cannot make entries for today please contact the administrator
                                                            </Text>
                                                        </TextContent>
                                                    </Bullseye>
                                                </PageGroup>
                                            </PageSection>

                                        </> : null}
                            {/* <Grid>
                                <GridItem span={4}></GridItem>
                                <GridItem span={4}>
                                    Please select a date: <DatePicker value={selectedDate} onChange={(e, newDate) => this.handleDate(newDate)} weekStart={1} />
                                </GridItem>
                                <GridItem span={4}>
                                </GridItem>
                            </Grid>
                            <div style={{ marginTop: 30 }}></div>
                            <Grid>
                                <GridItem span={1}></GridItem>
                                <GridItem span={8}>
                                    <div>
                                        <Tabs scrolling={false} activeKey={this.state.activeTabKey} onSelect={this.handleTabClick} isBox={true} aria-label="Tabs in the default overflow example" role="region">
                                            <Tab eventKey={0} onClick={() => this.setCurrentDate("0")} title={<TabTitleText>Monday <span class="badge">12.5</span></TabTitleText>} aria-label="Default overflow content users" />
                                            <Tab eventKey={1} onClick={() => this.setCurrentDate("1")} title={<TabTitleText>Tuesday <span class="badge">0</span></TabTitleText>} aria-label="Default overflow content users" />
                                            <Tab eventKey={2} onClick={() => this.setCurrentDate("2")} title={<TabTitleText>Wednesday <span class="badge">5</span></TabTitleText>} aria-label="Default overflow content users" />
                                            <Tab eventKey={3} onClick={() => this.setCurrentDate("3")} title={<TabTitleText>Thrusday <span class="badge">4.30</span></TabTitleText>} aria-label="Default overflow content users" />
                                            <Tab eventKey={4} onClick={() => this.setCurrentDate("4")} title={<TabTitleText>Friday <span class="badge">20.15</span></TabTitleText>} aria-label="Default overflow content users" />
                                            <Tab eventKey={5} onClick={() => this.setCurrentDate("5")} title={<TabTitleText>Saturday <span class="badge">3</span></TabTitleText>} aria-label="Default overflow content users" />
                                            <Tab eventKey={6} onClick={() => this.setCurrentDate("6")} title={<TabTitleText>Sunday <span class="badge">1</span></TabTitleText>} aria-label="Default overflow content users" />
                                        </Tabs>
                                    </div>
                                </GridItem>
                                <GridItem span={2}><Button onClick={this.handleAddModalToggle}>Add New Entry</Button></GridItem>
                                <GridItem span={1}><Button onClick={this.makeCheckOutEntry}>Check Out</Button></GridItem>
                            </Grid>
                            <div style={{ marginTop: 20 }}></div>
                            <TableComposable aria-label="TimeEntry Table">
                                <Thead>
                                    <Tr>
                                        <Th>SN</Th>
                                        <Th>Date</Th>
                                        <Th>From</Th>
                                        <Th>Thru</Th>
                                        <Th>Project</Th>
                                        <Th>Location</Th>
                                        <Td></Td>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {this.state.entryList !== undefined ?
                                        this.state.entryList.map((entry, index) => {
                                            return (
                                                <Tr>
                                                    <Td dataLabel="SN">{index + 1}</Td>
                                                    <Td>{entry.entryDate}</Td>
                                                    <Td dataLabel="SN">{entry.fromTime}</Td>
                                                    <Td dataLabel="SN">{entry.thruTime}</Td>
                                                    <Td dataLabel="SN">{entry.project.projectName}</Td>
                                                    <Td dataLabel="SN">{entry.location.locationName}</Td>
                                                    <Td isActionCell>
                                                        <OverflowMenu breakpoint="lg">
                                                            <OverflowMenuContent>
                                                                <OverflowMenuGroup groupType="button">
                                                                    <OverflowMenuItem>
                                                                        <SaveIcon style={{ cursor: 'pointer' }} onClick={() => this.handleEditModalToggle(entry)} />
                                                                    </OverflowMenuItem>
                                                                    <OverflowMenuItem>
                                                                        <TrashIcon style={{ cursor: 'pointer' }} onClick={() => this.removeEntry(entry.id)} />
                                                                    </OverflowMenuItem>
                                                                </OverflowMenuGroup>

                                                            </OverflowMenuContent>
                                                        </OverflowMenu>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                        : null}
                                </Tbody>
                            </TableComposable> */}
                        </PageGroup>
                    </PageSection>
                </Page >

                <Modal style={{ height: '300px' }} variant={ModalVariant.medium} title="Edit Time Entry" isOpen={this.state.isEditModalOpen} onClose={this.handleEditCloseModalToggle}
                    actions={[<Button key="confirm" variant="primary" onClick={this.updateEntry}>
                        Update
                    </Button>, <Button key="cancel" variant="link" onClick={this.handleEditCloseModalToggle}>
                        Cancel
                    </Button>]}>
                    <Form>
                        <Grid hasGutter md={3}>
                            <GridItem span={12}>
                                <FormGroup label="Date">
                                    <DatePicker isDisabled onChange={(e, newDate) => this.handleEditEntry("entryDate", newDate)} value={existingEntry.entryDate} weekStart={1} placeholder='Date' />
                                </FormGroup>
                            </GridItem>
                            <FormGroup label="Start Time">
                                <TimeField value={existingEntry.fromTime} name="fromTime" style={{ width: 100 }} onChange={this.handleEditTimeSelection} />
                                {/* <TextInput placeholder='' name='fromTime' onChange={(e) => this.handleNewEntry("fromTime", e)} value={newEntry.fromTime} type="text" aria-label="comment for time entry" /> */}
                            </FormGroup>
                            <FormGroup label="End Time">
                                <TimeField value={existingEntry.thruTime} name="thruTime" style={{ width: 100 }} onChange={this.handleEditTimeSelection} />
                                {/* <TextInput placeholder='End Time' name='thruTime' onChange={(e) => this.handleNewEntry("thruTime", e)} value={newEntry.thruTime} type="text" aria-label="comment for time entry" /> */}
                            </FormGroup>
                            <FormGroup label="Project">
                                <Select
                                    id='task-dropdown'
                                    variant={SelectVariant.single}
                                    onToggle={(event) => this.onToggleProjectDropdown(event)}
                                    onSelect={(event, selection) => this.onSelectProject(event, selection, 'edit')}
                                    selections={existingEntry.project ? existingEntry.project.projectName : ""}
                                    isOpen={projectDropdown}
                                    placeholderText="Project"
                                    hasInlineFilter
                                >
                                    {dropdownTaskItems}
                                </Select>
                            </FormGroup>
                            <FormGroup label="Location">
                                <Select
                                    id='task-dropdown'
                                    variant={SelectVariant.single}
                                    onToggle={(event) => this.onToggleLocationDropdown(event)}
                                    onSelect={(event, selection) => this.onSelectLocation(event, selection, 'edit')}
                                    selections={existingEntry.location ? existingEntry.location.locationName : ""}
                                    isOpen={locationDropdown}
                                    placeholderText="Location"
                                    hasInlineFilter
                                >
                                    {dropdownLocationItems}
                                </Select>
                            </FormGroup>
                            <GridItem span={12}>
                                <FormGroup label="Comments">
                                    <TextArea placeholder='Comments' name='comments' onChange={(e) => this.handleEditEntry("comments", e)} value={existingEntry.comments} type="text" aria-label="comment for time entry" />
                                </FormGroup>
                            </GridItem>
                        </Grid>
                    </Form>
                </Modal>

                <Modal style={{ height: '300px' }} variant={ModalVariant.medium} title="Add Time Entry" isOpen={this.state.isAddModalOpen} onClose={this.handleAddModalToggle}
                    actions={[<Button key="confirm" variant="primary" onClick={this.addEntry}>
                        Save
                    </Button>, <Button key="cancel" variant="link" onClick={this.handleAddModalToggle}>
                        Cancel
                    </Button>]}>
                    <Form>
                        <Grid hasGutter md={3}>
                            <GridItem span={12}>
                                <FormGroup label="Date">
                                    <DatePicker onChange={(e, newDate) => this.handleNewEntry("entryDate", newDate)} value={this.state.selectedTabDate} weekStart={1} placeholder='Date' />
                                </FormGroup>
                            </GridItem>
                            <FormGroup label="Start Time *">
                                <TimeField value={newEntry.fromTime} name="fromTime" style={{ width: 100 }} onChange={this.handleTimeSelection} />
                                {/* <TextInput placeholder='Start Time' name='fromTime' onChange={(e) => this.handleNewEntry("fromTime", e)} value={newEntry.fromTime} type="text" aria-label="comment for time entry" /> */}
                            </FormGroup>
                            <FormGroup label="Project *">
                                <Select
                                    id='task-dropdown'
                                    variant={SelectVariant.single}
                                    onToggle={(event) => this.onToggleProjectDropdown(event)}
                                    onSelect={(event, selection) => this.onSelectProject(event, selection)}
                                    selections={newEntry.project ? newEntry.project.projectName : ""}
                                    isOpen={projectDropdown}
                                    placeholderText="Project *"
                                    hasInlineFilter
                                >
                                    {dropdownTaskItems}
                                </Select>
                            </FormGroup>
                            <FormGroup label="Location *">
                                <Select
                                    id='task-dropdown'
                                    variant={SelectVariant.single}
                                    onToggle={(event) => this.onToggleLocationDropdown(event)}
                                    onSelect={(event, selection) => this.onSelectLocation(event, selection)}
                                    selections={newEntry.location ? newEntry.location.locationName : ""}
                                    isOpen={locationDropdown}
                                    placeholderText="Location *"
                                    hasInlineFilter
                                >
                                    {dropdownLocationItems}
                                </Select>
                            </FormGroup>
                            <GridItem span={12}>
                                <FormGroup label="Comments">
                                    <TextArea placeholder='Comments' name='comments' onChange={(e) => this.handleNewEntry("comments", e)} value={newEntry.comments} type="text" aria-label="comment for time entry" />
                                </FormGroup>
                            </GridItem>
                        </Grid>
                    </Form>
                </Modal>
            </>
        );
    }

};

export default TimeEntry;