import { SESSION_KEYS } from "./AppHeaders";

export function createSession(userData) {
    localStorage.setItem(SESSION_KEYS.TOKEN, userData.token);
    const jwt = parseJwt(userData.token);
    const exp = new Date(jwt.exp * 1000);
    localStorage.setItem(SESSION_KEYS.EXPIRY, exp.getTime());
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

export function isAuthenticated() {
    const sessionUser = localStorage.getItem(SESSION_KEYS.TOKEN);


    if (sessionUser && isExpired()) {
        invalidateSession();
        return false;
    }
    return sessionUser ? true : false;
}

function isExpired() {
    const exp = localStorage.getItem(SESSION_KEYS.EXPIRY);
    if (Date.now() <= exp) {
        return false;
    }
    return true;
}

export function invalidateSession() {
    localStorage.removeItem(SESSION_KEYS.TOKEN);
    localStorage.removeItem(SESSION_KEYS.EXPIRY);

    setTimeout(() => {
        window.location.reload();
    }, 500);
}

