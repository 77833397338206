import React from 'react';
// import brandImg2 from '@app/bgimages/2022_hydrograv.png';
import { LoginForm, LoginPage, ListVariant } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { login } from '../actions/actions';
import { createSession, isAuthenticated } from '../AppSession';
import { SESSION_KEYS } from '../AppHeaders';
// import { Buffer } from 'buffer';
import images from '../bg.jpg';
import logo from '../Final_variation-Insonix_Logo.png';

export const Login = () => {
    const [showHelperText, setShowHelperText] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [isValidUsername, setIsValidUsername] = React.useState(true);
    const [password, setPassword] = React.useState('');
    const [isValidPassword, setIsValidPassword] = React.useState(true);
    // const [isLoggedIn, setIsLoggedIn] = React.useState(isAuthenticated());
    const [isLoggedIn, setIsLoggedIn] = React.useState();

    const handleUsernameChange = (value) => {
        setShowHelperText(false);
        setIsValidUsername(true);
        setIsValidPassword(true);
        setUsername(value)
    };

    const handlePasswordChange = (value) => {
        setShowHelperText(false);
        setIsValidUsername(true);
        setIsValidPassword(true);
        setPassword(value);
    };

    const onLoginButtonClick = (event) => {
        event.preventDefault();
        let credentials = { username: username, password: password }; //'harkomal@insonix.com' //'testPass@123'
        localStorage.setItem('USER', username);
        login(credentials).then(data => { createSession(data.data); }).then(e => { setIsLoggedIn(true); window.location.reload(); }).catch(err => { console.log("error   ...." + err); setShowHelperText(true); setIsValidUsername(false); setIsLoggedIn(false); setIsValidPassword(false); });
    };

    const loginForm = (
        <LoginForm
            showHelperText={showHelperText}
            helperText="Invalid login credentials."
            helperTextIcon={<ExclamationCircleIcon />}
            usernameLabel="Username"
            usernameValue={username}
            onChangeUsername={handleUsernameChange}
            isValidUsername={isValidUsername}
            passwordLabel="Password"
            passwordValue={password}
            onChangePassword={handlePasswordChange}
            isValidPassword={isValidPassword}
            onLoginButtonClick={onLoginButtonClick}
            loginButtonLabel="Log in"
        />
    );
    

    return (
        <LoginPage
            footerListVariants={ListVariant.inline}
            brandImgSrc={logo}
            brandImgAlt="PatternFly logo"
            backgroundImgSrc={images}
            textContent="The mission statement of Insonix Inc. is the development of innovative and efficient solutions for business management systems."
            loginTitle="Log in to your account"
            loginSubtitle="Enter your credentials.">
            {loginForm}
        </LoginPage>
    );
};
