import axios from 'axios';
import { invalidateSession } from '../AppSession';
import { HEADERS } from '../AppHeaders';
import base64 from 'react-native-base64';

export const login = (data) => {
    const encryptData = base64.encode(data.username + ':' + data.password);
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth`, data, { headers: HEADERS.LOGIN(encryptData) });
}

export const getWeekTimeEntries = async (date) => {
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/timesheet/${date}/TW`, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const createTimeEntry = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/time-entry`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const updateTimeEntry = async (data) => {
    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/user/time-entry/${data.id}`, data, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const getProjectList = async () => {
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/project`, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const getLocationList = async () => {
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/location`, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const deleteTimeEntry = async (timeEntryId) => {
    return axios.delete(`${process.env.REACT_APP_API_BASE_URL}/user/time-entry/${timeEntryId}`, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const getCheckInCheckOutEntry = async (date) => {
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/time-entry/in-out/${date}`, {
        data: {}, headers: await HEADERS.SECURED()
    });
}

export const logout = () => {
    invalidateSession();
}
